<template>
  <main class="home-page-main">
    <SlideBanner type="BANNER_HOME_SLIDE" />
    <BannerItem type="BANNER_HOME_UI_NEW" />
<!--    <AppLink />-->
    <Questioning />
    <OnlineClass />
    <LibSupplies />
    <Teachers />
    <Comments />
    <News/>
  </main>
</template>

<script>
import BannerItem from '~/components/mlearn/layout/banner/item.vue'
// import AppLink from '~/components/hoi-bai-new/app-link.vue'
import Questioning from '~/components/hoi-bai-new/questioning.vue'
import OnlineClass from '~/components/hoi-bai-new/online-class.vue'
import LibSupplies from '~/components/hoi-bai-new/lib-n-supplies.vue'
import Teachers from '~/components/hoi-bai-new/teachers.vue'
import Comments from '~/components/hoi-bai-new/comments.vue'
import News from '~/components/hoi-bai-new/news.vue'
import SlideBanner from '~/components/gioi-thieu/slide.vue'
import Resource from '~/common/api/resource'
const metaResource = new Resource('config/seo')
export default {
  components: {
    SlideBanner,
    BannerItem,
    // AppLink,
    Questioning,
    OnlineClass,
    LibSupplies,
    Teachers,
    Comments,
    News
  },
  layout: 'thu-vien',
  async asyncData ({ route }) {
    // if (store.state.isMobile) {
    //   return redirect('/home/mobile')
    // }
    const meta = await metaResource.list({ path: route.path }).catch(() => {})
    const metaSeo = meta?.data || null
    const structuredData = {}
    return {
      metaSeo,
      structuredData
    }
  },
  data () {
    return {
      isMobile: false
    }
  },
  head () {
    if (!this.metaSeo) {
      return {
        link: [
          { rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath },
          { href: 'https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap', style: 'text/css', rel: 'stylesheet' }
        ]
      }
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        { hid: 'title', name: 'title', content: (this.metaSeo?.title || '') },
        { hid: 'description', name: 'description', content: (this.metaSeo?.description || '') },
        { hid: 'keywords', name: 'keywords', content: (this.metaSeo?.keyword || '') },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: (this.metaSeo?.title || '') },
        { hid: 'og:url', property: 'og:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'og:description', property: 'og:description', content: (this.metaSeo?.description || '') },
        { hid: 'og:image', property: 'og:image', content: (this.metaSeo?.image || '') },
        { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:title', property: 'twitter:title', content: (this.metaSeo?.title || '') },
        { hid: 'twitter:url', property: 'twitter:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'twitter:description', property: 'twitter:description', content: (this.metaSeo?.description || '') },
        { hid: 'twitter:image', property: 'twitter:image', content: (this.metaSeo?.image || '') }
      ],
      link: [
        { rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath },
        { href: 'https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap', style: 'text/css', rel: 'stylesheet' }
      ],
      script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
  },
  mounted () {
    this.isMobile = window.innerWidth <= 520
  }
}
</script>

<style lang="scss">
  .home-page-main{
    font-family: 'Sarabun', sans-serif;
  }
</style>
