<template>
  <div class="chat-ai-content" :class="{'show-info': info!==''}">
    <div class="chat-ai-container">
      <div id="chat_container">
        <div class="chat-header" :class="{'minimize':isAsk}">
          <div class="container">
            <div class="chat-header-content">
              <div class="guide-ai-img">
                <img src="/ui-new/images/question-ai.png" alt="qs-image-col">
              </div>
              <div class="guide-ai">
                <p>CHAT AI</p>
                <p>Còn <span>05</span> câu hỏi</p>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-body">
          <div class="container">
            <div
              v-for="(chat, i) in wrapper"
              :key="i"
              class="wrapper"
              :class="{ ai: chat.isAi }"
            >
              <Chat :chat="chat" :key="i"/>
            </div>
          </div>
        </div>
        <div class="chat-additional-btn">
          <div :class="{'active':info=='trick'}" @click="showInfo('trick')">
            <img src="@/assets/icons/ai/trick.svg">
          </div>
          <div :class="{'active':info=='note'}" @click="showInfo('note')">
            <img src="@/assets/icons/ai/note.svg">
          </div>
        </div>
      </div>
      <div class="chat-form-search-container">
        <div class="container ">
          <form @submit.prevent="submitQuestion" class="form-search-ai">
      <textarea
        rows="1"
        cols="1"
        placeholder="Nhập câu hỏi!"
        v-model="question"
        style="overflow: hidden; resize: none;"
        class="text-search-ai"
      ></textarea>
            <button type="submit" :disabled="myInterval !== null"><img src="@/assets/icons/ai/send-1.svg" alt="send"/></button>
          </form>
        </div>
      </div>
    </div>
    <div class="chat-addtion-info">
      <div class="close" @click="hideInfo()"><img src="@/assets/icons/ai/close.svg"/></div>
      <Note v-if="info=='note'"/>
      <Trick v-if="info=='trick'"/>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {uuid} from 'vue-uuid'
import Chat from '~/components/ai/Chat.vue'
import {SearchAI, GetAnswers} from '~/common/api/openai-api'
import Note from '~/components/ai/Note.vue'
import Trick from '~/components/ai/Trick.vue'

export default {
  components: {
    Trick,
    Note,
    Chat
  },
  data() {
    return {
      wrapper: [{
        isAi: true,
        value: 'Xin chào, tôi là Chat AI của Vilearn, tôi có thể giúp gì được cho bạn không?',
        isDefault: true
      }],
      isAsk: false,
      loading: false,
      question: '',
      answer: '',
      myInterval: null,
      info: ''
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  methods: {
    submitQuestion() {
      console.log(this.user)
      if (!this.user?.uuid) {
        this.$bvModal.show('modal-login')
        return
      }
      if (this.question === '') {
        return
      }
      try {
        this.loading = true
        this.wrapper.push({
          isAi: false,
          value: this.question.trim()
        })
        this.isAsk = true
        this.wrapper.push({
          isAi: true,
          value: 'Đang trả lời....'
        })
        const randomNumber = Math.random() * 100
        const today = new Date()
        const millisecondsSinceEpoch = today.getTime()
        const seconds = Math.floor(millisecondsSinceEpoch)
        const data = {
          question: this.question,
          requestId: uuid.v4() + '_' + randomNumber + '_' + seconds
        }
        // console.log('data : ', data)
        SearchAI(data).then((res) => {
          this.question = ''
          if (res.status === 0) {
            const dataRev = {
              question: res.question.trim(),
              requestId: res.requestId
            }
            this.myInterval = setInterval(() => {
              GetAnswers(dataRev).then((res2) => {
                if (res2.status === 0) {
                  clearInterval(this.myInterval)
                  this.myInterval = null

                  // Kiểm Tra anwser
                  // this.question = res2.answer.trim()
                  this.wrapper.pop()
                  this.wrapper.push({
                    isAi: true,
                    value: res2.answer.trim()
                  })
                }
              })
            }, 1000)
          } else if (res.status === 203) {
            this.$bvModal.show('modal-redirect-sub')
            this.$notify({
              type: 'warning',
              message: res.message
            })
          } else {
            return this.$notify({
              title: 'Lỗi',
              message: res.message,
              type: 'error'
            })
          }
        })
      } catch (error) {
        return this.$notify({
          title: 'Lỗi',
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.question = ''
      }
    },
    showInfo(type) {
      this.info = type
    },
    hideInfo() {
      this.info = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.form-search-ai {
  border: 2px solid #005FAF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background: #fff;
  padding: 0 14px;

  .text-search-ai {
    color: #1b1c20;
  }
}

.info-header {
  margin-top: 76px;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  color: #373535;
  border-bottom: 2px solid #9FDBF9;;
}

.close {
  position: absolute;
  top: 20px;
  left: 10px;
  cursor: pointer;

  img {
    width: 40px;
  }
}
</style>
<style lang="scss">
.ml-content {
  background: linear-gradient(180deg, rgba(2, 170, 255, 0.43) 0%, rgba(2, 170, 255, 0) 100%);
  margin-top: 82px !important;
  @media (max-width: 1366px) {
    margin-top: 72px !important;
  }
  @media (max-width: 992px) {
    margin-top: 60px !important;
  }
  @media (max-width: 576px) {
    margin-top: 52px !important;
  }
}
</style>
