<template>
  <div class="container ml-detail-video mb-5" v-if="item">
    <div class="breadcrumd-custom"><nuxt-link to="/khoa-hoc">Khóa học </nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <nuxt-link :to="'/khoa-hoc/chuyen-de/' + item.topic.slug">{{ item.topic.name }}</nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">{{item.name}}</span></div>
    <ViewVideo :item="item" />
    <Relation title="Video cùng khóa học" class="video-related mt-3" type="all" :id="item.id"/>
    <Relation title="Video bài giảng liên quan" class="video-related mt-3" type="related" :id="item.id"/>
    <Comment class="video-comment mt-3" :object-id="item.id" />
  </div>
</template>

<script>
import cookieParser from 'cookieparser'
import Resource from '~/common/api/resource'
import Comment from '~/components/mlearn/comment'
import Relation from '~/components/mlearn/video/relation.vue'
import ViewVideo from '~/components/mlearn/video/view.vue'
const videoResource = new Resource('library/file/getBySlug')
export default {
  components: {
    ViewVideo,
    Relation,
    Comment
  },
  layout: 'mlearn',
  middleware ({ store, route, redirect, from }) {
    if (!store.state.authenticated) {
      return redirect('/khoa-hoc?redirect=' + encodeURIComponent(route.fullPath))
    }
  },
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    let token = ''
    if (req && req.headers && req.headers.cookie) {
      const requestCookies = cookieParser.parse(req.headers.cookie)
      token = requestCookies.access_token || ''
    }
    const data = await videoResource.list({ slug: params.slug }, token)
      .catch(() => {
        return error({ statusCode: 404, title: 'Video không tồn tại', message: 'Video đã bị xóa hoặc bị báo cáo vi phạm.' })
      })
    if (!data) {
      return error({ statusCode: 404, title: 'Video không tồn tại', message: 'Video đã bị xóa hoặc bị báo cáo vi phạm.' })
    }
    const item = data.data
    return {
      item
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .ml-detail-video{
    .video-related{
      background: var(--white);
      box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
      border-radius: 8px;
      padding: 20px 24px 10px 24px;
      @media (max-width:1366px) {
        padding: 18px;
      }
      @media (max-width:576px) {
        padding: 16px;
      }
    }
    .video-comment{
      background: var(--white);
      box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
      border-radius: 8px;
      padding: 20px 24px 10px 24px;
      @media (max-width:1366px) {
        padding: 18px;
      }
      @media (max-width:576px) {
        padding: 16px;
      }
    }
  }
</style>
