<template>
  <div class="ml-goi-cuoc container mb-5">
    <!--    <div class="breadcrumd-custom"><nuxt-link to="/">Hỏi bài</nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">Gói cước</span></div>-->
    <div class="row list-sub">
      <div class="col-lg-3"></div>
      <div class="col-lg-3 pb-3" v-for="sub in listSub" :key="sub.id">
        <div class="sub-item">
          <div class="title font-weight-bold text-lg-center">{{ sub.name }} <span class="type d-lg-none">({{ sub.title }})</span></div>
          <div class="price text-lg-center">{{ $helper.formatMoney(sub.price, '.') }}đ <span>/{{ sub.unit }}</span></div>
          <div class="type text-center d-none d-lg-block">{{ sub.title }}</div>
          <div class="other" v-html="sub.description"></div>
          <div class="action text-right">
            <button @click="showMessage(sub)">{{ sub.register ? 'Hủy' : 'Đăng ký' }}</button>
          </div>
          <div class="mt-3">
            <p v-if="sub.register">(Thời hạn sử dụng đến : {{ sub.expired_date | formatDateDetailPackage }}. Gói cước tự động gia hạn)</p>
            <p></p>
          </div>
        </div>
      </div>
      <!--      <div class="col-lg-3 pb-3">-->
      <!--        <div class="sub-item">-->
      <!--          <div class="title font-weight-bold text-lg-center">GÓI VIP NGÀY</div>-->
      <!--          <div class="price text-lg-center">5.000đ <span>/ngày</span></div>-->
      <!--          <div class="type text-center d-none d-lg-block">Gia hạn theo ngày</div>-->
      <!--          <div class="other">-->
      <!--            <p><span>&#10003; </span>200MB data/ngày</p>-->
      <!--            <p><span>&#10003; </span>Hỏi gia sư 02 câu hỏi</p>-->
      <!--            <p><span>&#10003; </span>Mở khoá toàn bộ các khoá học/ tài liệu học trên trang</p>-->
      <!--            <p><span>&#10003; </span>Tham gia thi thử, làm các bài test có hệ thống chấm điểm và xem kết quả</p>-->
      <!--          </div>-->
      <!--          <div class="action text-right">-->
      <!--            <button @click="showMessageDay()">Đăng kí</button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="col-lg-3 pb-3">-->
      <!--        <div class="sub-item">-->
      <!--          <div class="title font-weight-bold text-lg-center">GÓI VIP THÁNG</div>-->
      <!--          <div class="price text-lg-center">40.000đ <span>/tháng</span></div>-->
      <!--          <div class="type text-center d-none d-lg-block">Gia hạn theo tháng</div>-->
      <!--          <div class="other">-->
      <!--            <p><span>&#10003; </span>3GB data/30 ngày</p>-->
      <!--            <p><span>&#10003; </span>Hỏi gia sư 15 câu hỏi</p>-->
      <!--            <p><span>&#10003; </span>Mở khoá toàn bộ các khoá học/ tài liệu học trên trang</p>-->
      <!--            <p><span>&#10003; </span>Tham gia thi thử, làm các bài test có hệ thống chấm điểm và xem kết quả</p>-->
      <!--          </div>-->
      <!--          <div class="action text-right">-->
      <!--            <button @click="showMessage()">Đăng kí</button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <b-modal hide-footer hide-header id="modal-notify-sub" centered v-model="showModal">
      <img src="~/mlearn/icons/modal/close-modal.svg" alt="close" class="close position-absolute pointer" @click="showModal = false"/>
      <div class="title">Thông báo</div>
      <div class="description">{{ message }}</div>
    </b-modal>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'

const subResource = new Resource('price/sub')
export default {
  layout: 'mlearn',
  data () {
    return {
      showModal: false,
      listSub: [],
      message: ''
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  created () {
    this.loadSub()
  },
  methods: {
    showMessage (sub) {
      if (this.isMobile) {
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        const isIOS = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']
          .includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        if (isIOS) {
          window.open(`sms:${sub.service_number}?&body=${sub.register ? sub.unreg_syntax : sub.reg_syntax}`, '_blank')
        } else {
          window.open(`sms:${sub.service_number}?body=${sub.register ? sub.unreg_syntax : sub.reg_syntax}`, '_blank')
        }
        return
      }
      this.showModal = true
      this.message = `Để xác nhận ${sub.register ? 'hủy' : 'đăng ký'} gói ${sub.unit}, mời Quý khách soạn tin ${sub.register ? sub.unreg_syntax : sub.reg_syntax} gửi ${sub.service_number} xác nhận ${sub.register ? 'hủy' : 'đăng ký'} DV. Phí DV ${this.$helper.formatMoney(sub.price, '.') + 'đồng/' + sub.unit.toLowerCase()}, ${sub.title.toLowerCase()}.`
      // this.showModal = true
      // this.message = 'Để xác nhận đăng ký gói Tháng, mời Quý khách soạn tin VR40 gửi 888 xác nhận đăng ký DV. Phí DV 40.000đồng/tháng, gia hạn theo tháng.'
    },
    showMessageDay (sub) {
      if (this.isMobile) {
        window.open('sms:888?&body=DK VA40', '_blank')
        return
      }
      this.showModal = true
      this.message = `Để xác nhận ${sub.register ? 'hủy' : 'đăng ký'} gói ${sub.unit}, mời Quý khách soạn tin ${sub.register ? sub.unreg_syntax : sub.reg_syntax} gửi ${sub.service_number} xác nhận ${sub.register ? 'hủy' : 'đăng ký'} DV. Phí DV ${this.$helper.formatMoney(sub.price, '.') + 'đồng/' + sub.unit.toLowerCase()}, ${sub.title.toLowerCase()}.`
      // this.showModal = true
      // this.message = 'Để xác nhận đăng ký gói Ngày, mời Quý khách soạn tin VR5 gửi 888 xác nhận đăng ký DV. Phí DV 5.000đồng/ngày, gia hạn theo ngày.'
    },
    async loadSub () {
      const {data} = await subResource.list()
      this.listSub = data
    }
  }
}
</script>

<style lang="scss" scoped>
.ml-goi-cuoc {
  .list-sub {
    .sub-item {
      position: relative;
      background: var(--white);
      box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
      border-radius: 8px;
      padding: 24px;
      @media (max-width: 576px) {
        padding: 16px;
      }

      &::after {
        content: '';
        position: absolute;
        background: #4CA3EC;
        box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
        border-radius: 0px 0px 8px 8px;
        height: 8px;
        width: 100%;
        bottom: 0;
        left: 0;
        @media (max-width: 576px) {
          border-radius: 8px 0px 0px 8px;
          height: 100%;
          width: 8px;
          bottom: 0;
          left: 0;
        }
      }

      .title {
        font-size: 16px;

        .type {
          font-size: 12px;
          color: #65676B;
        }
      }

      .price {
        font-weight: 600;
        color: var(--primary);
        font-size: 26px;
        @media (max-width: 576px) {
          padding-top: 16px;
        }

        span {
          font-size: 22px;
          @media (max-width: 576px) {
          }
        }
      }

      .type {
        font-size: 12px;
        color: #65676B;
      }

      .other {
        color: #65676B;
        font-size: 16px;
        margin-top: 10px;
        @media (max-width: 576px) {
          font-size: 13px;
          margin-top: 8px;
        }

        p {
          margin: 0;

          span {
            color: #00AD42;
            font-size: larger;
            font-weight: bold;
          }
        }
      }

      .action {
        margin-top: 40px;
        @media (max-width: 576px) {
          margin-top: 16px;
        }

        button {
          background: var(--primary);
          box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
          border-radius: 8px;
          font-weight: 500;
          font-size: 16px;
          text-align: center;
          color: var(--white);
          width: 100%;
          padding: 14px 0;
          @media (max-width: 1366px) {
            padding: 14px 0;
          }
          @media (max-width: 576px) {
            padding: 10px 30px;
            width: auto;
          }

          &:hover {
            box-shadow: 0px 3px 6px rgba(202, 202, 202, 0.34);
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
#modal-notify-sub {
  .modal-dialog {
    max-width: 585px;
    @media (max-width: 576px) {
    }

    .modal-content {
      .modal-body {
        padding: 24px;
        text-align: center;
        @media (max-width: 1366px) {
          padding: 18px;
        }
        @media (max-width: 576px) {
          padding: 16px;
        }

        .close {
          right: 15px;
          top: 15px;
        }

        .title {
          font-weight: bold;
          font-size: 24px;
          @media (max-width: 1366px) {
            font-size: 18px;
          }
          @media (max-width: 576px) {
            font-size: 16px;
          }
        }

        .description {
          margin-top: 20px;
          font-size: 16px;
          @media (max-width: 576px) {
          }
        }
      }
    }
  }
}
</style>
