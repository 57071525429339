<template>
  <div id="download-app">
    <div class="content">
      <nuxt-link to="/"><div class="logo"><img src="/icon/logo_footer.png"></div></nuxt-link>
      <div class="download-btn" @click="downloadApp">Download App</div>
      <div class="description">Please press the button to proceed to your app</div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'event',
  methods: {
    downloadApp () {
      let appLink = 'https://play.google.com/store/apps/details?id=vn.com.elcom.colearn'
      const userAgent = process.server ? window.req.headers['user-agent'] : navigator.userAgent

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        appLink = 'https://apps.apple.com/vn/app/colearn/id1548249734'
      }

      window.location.href = appLink
    }
  }
}
</script>

<style lang="scss" scoped>
#download-app {
  background: #e1eaf9;
  height: 100vh;
  position: relative;
  .content {
    width: 100%;
    position: absolute;
    top: 20%;
    text-align: center;
    .logo {
      margin-bottom: 35px;
      img {
        width: 200px;
        height: auto;
      }
    }
    .download-btn {
      background-color: #007bff;
      border-radius: .25rem;
      padding: .5rem 1rem;
      color: var(--white);
      cursor: pointer;
      width: 200px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .description {
      color: #172948;
    }
  }
}
</style>
